<template>
  <div class="sb_list-filter-bar">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sb_list-filter-bar {
  display: grid;
  align-items: center;
  padding: 0.8rem 0;
  grid-column-gap: 1rem;
}
</style>
