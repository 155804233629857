<template>
  <div class="sb_list-row">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sb_list-row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  padding: 0.8rem 0;
  border-bottom: solid 1px $brand-light-gray;
  line-height: 1.1;
  position: relative;
  z-index: 3;
  transition: 0.3s background-color;

  //
  //  STATE - HOVER
  //
  &:hover {
    background: $brand-lightest-gray;
    transition-duration: 0s;
  }
}
</style>
