<template>
  <div class="sb_list-head">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sb_list-head {
  position: relative;
  display: grid;
  align-items: center;
  line-height: 1.1;

  //
  // FAUX LINE
  //
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    background: $brand-light-gray;
    z-index: 1;
  }
}
</style>
