<template>
  <div class="sb_list-head_col">
    <span><slot></slot></span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sb_list-head_col {
  position: relative;
  z-index: 2;
  padding: 0 0 0 1.6rem;
  span {
    background: $brand-white;
    white-space: nowrap;
    box-shadow: 0 0 0 10px $brand-white;
    font-weight: $semibold;
    @include fontSizeLarge;
  }
}
</style>
