<template>
  <div class="sb_cards-list">
    <sb-loading v-if="loading" position="absolute" width="100%" height="100%" />

    <!-- mode === 'assigned-cards' -->
    <sb-call-out v-if="mode === 'assigned-cards'">
      <template #left>💡</template>
      <template #right>
        <template v-if="!loading && cards.length === 0">
          Er zijn nog geen kaarten toegewezen.
          <router-link :to="{ name: 'ResourceCards' }">
            Bekijk de kaartenbak om toch te oefenen.
          </router-link>
        </template>
        <template v-if="!loading && cards.length">
          Hieronder vind je leeskaarten die voor jou zijn klaargezet door je
          leraar of school. Klik op een kaart en dan "Start oefening" om deze te
          gaan oefenen.
        </template>
      </template>
    </sb-call-out>

    <sb-spacer height="20" />
    <div class="flex gap-1">
      <template v-if="showExerciseStatusEnabled">
        <div class="flex flex-align-center gap-1">
          Laat afgeronde kaarten zien?
          <i-switch v-model="filters.completed">
            <span slot="open">Ja</span>
            <span slot="close">Nee</span>
          </i-switch>
        </div>
      </template>

      <div
        v-if="get(platformSettings, 'readingComprehension')"
        class="flex flex-align-center gap-1"
      >
        Alleen begrijpend lezen kaarten?
        <i-switch v-model="filters.readingComprehension">
          <span slot="open">Ja</span>
          <span slot="close">Nee</span>
        </i-switch>
      </div>
    </div>

    <sb-spacer height="20" />

    <sb-list-filter-bar style="grid-template-columns: repeat(5, 1fr)">
      <!-- <i-select placeholder="Begrijpend lezen">
        <i-option></i-option>
      </i-select> -->
      <i-input
        v-model="filters.title"
        suffix="ios-search"
        placeholder="Zoek op naam"
        style="width: auto"
        clearable
      />

      <i-select v-model="filters.cardType" placeholder="Kaarttype" clearable>
        <i-option
          v-for="cardType in cardTypes"
          :key="cardType.id"
          :value="cardType.name"
        >
          {{ get(cardType, 'name') }}
        </i-option>
      </i-select>

      <sb-reading-level-select
        v-model="filters.cardLevels"
        placeholder="Niveau"
      />

      <i-select
        v-model="filters.methodologies"
        placeholder="Methodes"
        clearable
        multiple
        filterable
      >
        <i-option
          v-for="methodology in filteredCardMethodologies"
          :key="get(methodology, 'id')"
          :value="get(methodology, 'name')"
        >
          {{ get(methodology, 'name') }}
        </i-option>
      </i-select>
    </sb-list-filter-bar>

    <sb-spacer height="20" />

    <div v-if="!filteredCards.length && !loading">
      Er zijn geen kaarten gevonden die voldoen aan de gestelde filters. Pas de
      filters aan of probeer een andere zoekopdracht.
    </div>

    <sb-list-head v-else :style="gridTemplateColumns">
      <sb-list-row-col v-if="canSelect">
        <div v-if="multiple" class="select-all">
          <checkbox v-if="multiple" @input="handleSelectAll" />
        </div>
      </sb-list-row-col>
      <sb-list-head-col> Kaartnr. </sb-list-head-col>
      <sb-list-head-col> Kaartnaam </sb-list-head-col>
      <sb-list-head-col> Type </sb-list-head-col>
      <sb-list-head-col> Niveau </sb-list-head-col>
      <sb-list-head-col> Methodes </sb-list-head-col>
      <sb-list-head-col v-if="showExerciseStatusEnabled">
        Afgerond
      </sb-list-head-col>
    </sb-list-head>

    <sb-list-row
      v-for="card in filteredCards"
      :key="card.id"
      :style="gridTemplateColumns"
      class="sb_card-list_row"
      @click.native="
        canSelect
          ? multiple
            ? previewCard(card.id)
            : $emit('input', card.id)
          : previewCard(card.id)
      "
    >
      <sb-list-row-col v-if="canSelect" @click.native.stop>
        <radio
          v-if="!multiple"
          size="large"
          :value="value === card.id"
          @click.native="$emit('input', card.id)"
        />
        <checkbox
          v-else
          :value="value.includes(card.id) || lockedIds.includes(card.id)"
          :disabled="lockedIds.includes(card.id)"
          @input="
            $emit(
              'input',
              $event
                ? Array.from(new Set(value.concat(card.id)))
                : Array.from(new Set(value.filter((id) => id !== card.id))),
            )
          "
        />
      </sb-list-row-col>

      <sb-list-row-col>
        <sb-button
          size="small"
          :button-type="
            showExerciseStatusEnabled && isExerciseCompleted(card.id)
              ? 'green'
              : undefined
          "
          unresponsive
        >
          {{ card.number || '?' }}
        </sb-button>
      </sb-list-row-col>

      <sb-list-row-col> {{ card.title }} </sb-list-row-col>

      <sb-list-row-col>
        {{ card.type }}
      </sb-list-row-col>

      <sb-list-row-col> {{ card.level }} </sb-list-row-col>

      <sb-list-row-col>
        {{ (get(card, 'methodologies') || []).join(', ') }}
      </sb-list-row-col>

      <sb-list-row-col v-if="showExerciseStatusEnabled" class="check">
        <sb-icon v-if="isExerciseCompleted(card.id)" icon-id="icon_check" />

        <div
          v-if="canRefreshExercise"
          class="refresh"
          @click="refreshExercise($event, card.id)"
        >
          <sb-icon v-if="isExerciseCompleted(card.id)" icon-id="icon_refresh" />
        </div>
      </sb-list-row-col>
    </sb-list-row>
  </div>
</template>

<script>
import SbListFilterBar from '@/components/SbListFilterBar';
import SbListHead from '@/components/SbListHead';
import SbListHeadCol from '@/components/SbListHeadCol';
import SbListRow from '@/components/SbListRow';
import SbListRowCol from '@/components/SbListRowCol';
import gql from 'graphql-tag';
import SbLoading from './SbLoading.vue';
import { GraphQL } from '@/lib/graphql';
import { readingLevelMixin } from '@/lib/reading-level';
import { fetchAllCardsMixin } from '@/mixins/fetchAllCardsMixin';
import { platformSettingsMixin } from '@/mixins/platformSettingsMixin';
import SbReadingLevelSelect from '@/components/SbReadingLevelSelect.vue';
import SbCallOut from './SbCallOut.vue';

export default {
  name: 'SbCardsList',

  components: {
    SbListFilterBar,
    SbListHead,
    SbListHeadCol,
    SbListRow,
    SbListRowCol,
    SbLoading,
    SbReadingLevelSelect,
    SbCallOut,
  },

  mixins: [
    platformSettingsMixin,
    readingLevelMixin,
    fetchAllCardsMixin((self) => ({
      onStart: () => (self.loading = true),
      onCards: (cards) => (self.cards = cards),
      onEnd: () => (self.loading = false),
      onError: () => (self.loading = false),
    })),
  ],

  props: {
    value: { type: [String, Number, Array], default: undefined },
    defaultFilters: {
      type: Object,
      default: () => ({
        completed: true,
        title: '',
        cardType: undefined,
        cardLevels: [],
        methodologies: [],
        readingComprehension: false,
      }),
    },
    studentIds: { type: Array, default: undefined },
    multiple: { type: Boolean, default: false },
    withLinkedProblems: { type: Boolean, default: false },
    mixCompleted: { type: Boolean, default: true },
    groupCardIds: { type: Array, default: undefined },
    lockedIds: { type: Array, default: () => [] },
    mode: { type: String, default: 'default' },
    showExerciseStatusEnabled: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        completed: true,
        title: '',
        cardType: undefined,
        cardLevels: [],
        methodologies: [],
        readingComprehension: false,
        ...this.defaultFilters,
        ...JSON.parse(
          localStorage.getItem(
            `cards-list-filters:${this.$route.params.studentId}`,
          ) || 'null',
        ),
      },
      loading: false,
      cards: [],
      availableCardsIds: [],
    };
  },

  computed: {
    canSelect() {
      return !!this.$listeners.input;
    },

    canRefreshExercise() {
      return this.showExerciseStatusEnabled && this.$user.role === 'COACH';
    },

    gridTemplateColumns() {
      return `grid-template-columns: ${
        this.canSelect ? '40px 110px' : '150px'
      } 1fr 200px 150px 1fr ${this.showExerciseStatusEnabled ? '140px' : ''}`;
    },

    filteredCardMethodologies() {
      return Array.from(
        new Set(this.filteredCards.map((card) => card.methodologies).flat()),
      )
        .map((e) => this.cardMethodologies.find((m) => m.name === e))
        .filter(Boolean)
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    filteredCards() {
      let filtered, done, todo;
      const {
        cardType,
        cardLevels,
        completed,
        methodologies,
        title,
        readingComprehension,
      } = this.filters;

      const practiceAmounts = this.practiceAmountLookup;
      const doFilterCardLevels = !!cardLevels.filter(Boolean).length;
      const doFilterMethodologies = !!methodologies.length;
      const doFilterAssignedCards = this.mode === 'assigned-cards';

      filtered = this.cards.filter((card) => {
        const rules = [
          // filter reading comprehension cards
          this.platformSettings?.readingComprehension && readingComprehension
            ? !!card.addReadingComprehension
            : true,

          // filter by search term
          card.title.toLowerCase().includes(title.toLowerCase()),

          // filter by completion
          this.showExerciseStatusEnabled
            ? completed || !practiceAmounts?.[card.id]?.count
            : true,

          // filter by card type
          cardType ? card.type === cardType : true,

          // filter by card level
          doFilterCardLevels ? cardLevels.includes(card.level) : true,

          // filter by card methodologies
          doFilterMethodologies
            ? card.methodologies.some((e) => methodologies.includes(e))
            : true,

          // filter assigned cards
          doFilterAssignedCards
            ? this.availableCardsIds.includes(card.id)
            : true,
        ];

        return rules.every((statement) => statement === true);
      });

      let sorted = filtered
        .map((card) => ({ ...card, number: parseInt(card.number || '0') }))
        .sort((a, b) => (a.number < b.number ? -1 : 1));

      if (this.$user.role === 'STUDENT' && !this.mixCompleted) {
        done = sorted.filter((e) => !!practiceAmounts?.[e.id]);
        todo = sorted.filter((e) => !practiceAmounts?.[e.id]);
        sorted = todo.concat(done);
      }

      return sorted;
    },
  },

  watch: {
    mode: {
      handler(val) {
        console.log('mode', val);
      },
    },
    filters: {
      handler(value) {
        const role = this.$user.role;
        const studentId = this.$route.params.studentId;

        this.$emit('filters-change', value);

        if (role !== 'COACH' || !studentId) return;

        localStorage.setItem(
          `cards-list-filters:${studentId}`,
          JSON.stringify(value),
        );
      },
      immediate: true,
      deep: true,
    },

    cards(value) {
      this.$emit('cards-change', value);
    },

    practiceAmountLookup(value) {
      this.$emit('practice-amounts-change', value);
    },
  },

  async mounted() {
    if (this.mode === 'assigned-cards') {
      await this.fetchAvailableCards();
    }
    this.$apollo.queries.practiceAmountLookup.refresh();
    this.fetchAllCards();
  },

  methods: {
    getCardExerciseId(cardId) {
      return this.practiceAmountLookup[cardId]?.id;
    },

    async startExercise(cardId) {
      try {
        const { data, errors } = await this.$apollo.mutate({
          variables: {
            input: {
              cardId,
              readingLevel: this.getCardById(cardId).level,
            },
          },
          mutation: gql`
            mutation DashboardStudent_CreateCardExercise(
              $input: CreateCardExerciseInput!
            ) {
              createCardExercise(input: $input) {
                id
              }
            }
          `,
        });

        if (errors) {
          throw new Error(errors.map((e) => e.message).join('\n'));
        }

        this.$router.push({
          name: 'StudentParentCardExercise',
          query: { cardId, exerciseId: data.createCardExercise.id },
        });
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },

    async fetchAvailableCards() {
      try {
        const { data, errors } = await this.$apollo.query({
          fetchPolicy: 'network-only',
          query: gql`query CardsList_assigned {
              getUserById(id: "${this.studentIds[0]}") {
              id
              availableCardsIds
              groupAvailableCardsIds
            }
          }`,
        });

        if (errors) {
          console.log(errors);
          throw new Error();
        }

        this.availableCardsIds = [
          ...data.getUserById.availableCardsIds,
          ...data.getUserById?.groupAvailableCardsIds,
        ];
      } catch (error) {
        this.$showGenericError();
      } finally {
        this.$watch('toggle', (value) => {
          value ? undefined : (this.selectedCards = []);
        });
      }
    },

    previewCard(cardId) {
      const route = this.$router.resolve({
        name: 'SessionCardPreview',
        query: { cardId },
      });

      this.$Modal.confirm({
        title: 'Kaart bekijken',
        content: 'De kaart wordt geopend in een nieuw venster.',
        onOk: () => window.open(route.href, '_blank'),
      });
    },

    isExerciseCompleted(cardId) {
      const card = this.practiceAmountLookup?.[cardId];
      return !!card && card.count > 0 && !!card.finishedAt;
    },

    async refreshExercise(event, cardId) {
      if (event) event.stopPropagation();

      const studentId = this.$route.params.studentId;

      if (!cardId) {
        // Should not be possible, but just in case
        throw new Error('Er is iets mis gegaan. Oefening id niet gevonden.');
      }

      try {
        await this.$apollo.mutate({
          // This is a hacky way to refresh the card exercises.
          // Currently it is using the finishedAt value to determine if the exercise is finished.
          // If we want to refresh the card exercise we need to set the finishedAt value to null.

          // This is not ideal, and should probably be changed in the future.

          mutation: gql`
            mutation CardsList_RefreshCard($input: ResetCardExercisesInput!) {
              resetCardExercises(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              cardId,
              studentId,
            },
          },
        });
        this.$apollo.queries.practiceAmountLookup.refresh();
      } catch (error) {
        this.$showGenericError();
      }
    },

    getCardById(cardId) {
      return this.cards?.find((card) => card?.id === cardId);
    },

    handleSelectAll(checked) {
      this.$emit(
        'input',
        checked
          ? Array.from(new Set(this.filteredCards.map((card) => card.id)))
          : [],
      );
    },
  },

  apollo: {
    cardTypes: createTaxQuery('cardTypes'),
    // cardLevels: createTaxQuery('cardLevels'),
    cardMethodologies: createTaxQuery('cardMethodologies'),
    problems: createTaxQuery('problems', ['id', 'title']),

    studentReadingLevels: {
      skip() {
        // If coach and studentIds are not set the user is in the 'Kaartenbak'. Don't filter then.
        return this.$user.role !== 'COACH' || !this.studentIds?.length;
      },
      query() {
        return gql`
          query StudentReadingLevels(
            $first: Int
            $after: String
            $last: Int
            $before: String
            $filter: TracksFilter
            $orderBy: TracksOrderBy
          ) {
            tracks(
              first: $first
              after: $after
              last: $last
              before: $before
              filter: $filter
              orderBy: $orderBy
            ) {
              edges {
                node {
                  id
                  readingLevels
                  active
                }
              }
            }
          }
        `;
      },
      variables() {
        return {
          first: 10,
          filter: {
            active: true,
            users: [
              {
                userId: {
                  equals: this.studentIds[0],
                },
              },
            ],
          },
          orderBy: {
            createdAt: 'DESC',
          },
        };
      },

      update(data) {
        this.filters.cardLevels = data.tracks.edges
          .reduce((acc, { node }) => {
            if (node.active) {
              return [...acc, ...node.readingLevels];
            }
            return acc;
          }, [])
          .flat();
      },
    },

    activeTrack: {
      fetchPolicy: 'cache-and-network',

      skip() {
        return this.$user.role !== 'STUDENT' || this.mode === 'assigned-cards';
      },

      variables() {
        return {
          userId: this.$user.id,
          first: GraphQL.MAX_SAFE_INTEGER,
          filter: {
            users: [
              {
                userId: { equals: this.$user.id },
                role: 'TRACK_STUDENT',
              },
            ],
            active: true,
          },
        };
      },

      update(data) {
        const readingLevels = data.getUserById.tracks.edges
          .map(({ node }) => node.readingLevels)
          .filter(Boolean);
        this.filters.cardLevels = readingLevels ? readingLevels.flat() : [];
      },

      query: gql`
        query CardsList_FindActiveTrack(
          $userId: ID!
          $first: Int
          $filter: TracksFilter
        ) {
          getUserById(id: $userId) {
            id
            tracks(first: $first, filter: $filter) {
              edges {
                node {
                  id
                  readingLevels
                }
              }
            }
          }
        }
      `,
    },

    practiceAmountLookup: {
      fetchPolicy: 'cache-and-network',

      skip() {
        return this.showExerciseStatusEnabled !== true;
      },

      variables() {
        return {
          filter: {
            studentId: { in: this.studentIds },
          },
        };
      },

      update(data) {
        return data.cardExercises.reduce((acc, exercise) => {
          if (acc[exercise.cardId] == undefined) {
            acc[exercise.cardId] = {
              count: 0,
              finishedAt: exercise.finishedAt,
              id: exercise.id,
            };
          }

          if (exercise.finishedAt) {
            acc[exercise.cardId].id = exercise.id;
            acc[exercise.cardId].count++;
            acc[exercise.cardId].finishedAt = exercise.finishedAt;
            acc[exercise.cardId].repeat = exercise.repeat;
          }

          return acc;
        }, {});
      },

      query() {
        return gql`
          query CardsList_practiceAmount($filter: CardExercisesFilter!) {
            cardExercises(filter: $filter) {
              id
              cardId
              finishedAt
              repeat
            }
          }
        `;
      },
    },
  },
};

function createTaxQuery(name, fields = ['id', 'slug', 'name']) {
  return {
    query: gql`
        query CardsList_taxonomy_${name} {
          ${name} { ${fields.join(' ')} }
        }
      `,

    update(data) {
      return data[name]
        ?.map((node) => node)
        .slice()
        .sort((a, b) =>
          (a.slug || a.name || a.title) > (b.slug || b.name || b.title)
            ? 1
            : -1,
        );
    },
  };
}
</script>

<style lang="scss" scoped>
.sb_cards-list {
  position: relative;
}

.sb_card-list_row {
  cursor: pointer;
}

.select-all {
  background: $brand-white;
  width: 20px;
  z-index: 2;
  position: relative;
  padding: 0 10px;
  box-sizing: content-box;
  margin: 0 0 0 -10px;
  overflow-x: hidden;
}

.check {
  color: $brand-green;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  width: calc(100% - 1.6rem);
}

.check {
  .refresh {
    padding-left: 0.5rem;
    color: #d9d9d9;
    font-size: 0.9rem;
    margin: auto;
  }
}
</style>

<style lang="scss">
.sb_cards-list {
  .sb_list-row {
    &:hover {
      color: $brand-primary;
    }
  }
}
</style>
