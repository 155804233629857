<template>
  <div class="sb_list-row-col">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sb_list-row-col {
  padding: 0 0 0 1.6rem;

  .sb_radio {
    transform: translateY(-10px);
  }

  .sb_checkbox {
    transform: translateY(-8px);
  }

  button {
    margin: 0 0.1rem 0.3rem 0.1rem;
  }
}
</style>
