<template>
  <div>
    <i-select
      :key="placement"
      :value="value"
      :placement="placement"
      :placeholder="placeholder"
      class="important"
      clearable
      multiple
      filterable
      class-name="test"
      @input="$emit('input', $event === 'EMPTY' ? undefined : $event)"
    >
      <i-option v-for="level in ReadingLevel" :key="level" :value="level">
        {{ level }}
      </i-option>
    </i-select>
  </div>
</template>

<script>
import { readingLevelMixin } from '@/lib/reading-level';

export default {
  mixins: [readingLevelMixin],
  props: {
    optional: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Selecteer niveau' },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      placement: 'bottom-end',
    };
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  mounted() {
    this.placement = this.getPlacement(window.outerHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize(e) {
      this.placement = this.getPlacement(e.target.outerHeight);
    },
    getPlacement(currentHeight) {
      const maxHeight = 1000;
      return currentHeight > maxHeight ? 'bottom-end' : 'top-end';
    },
  },
};
</script>

<style lang="scss" scoped>
.important {
}
</style>
