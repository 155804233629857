import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';

export const ReadingLevel = Object.freeze({
  M3: 'M3',
  E3: 'E3',
  M4: 'M4',
  E4: 'E4',
  M5: 'M5',
  E5: 'E5',
  M6: 'M6',
  E6: 'E6',
  M7: 'M7',
  E7: 'E7',
  M8: 'M8',
  // E8: 'E8', disable this reading level as per task 29030872
});

/**
 * This is a placeholder method for converting our current reading levels that start with `M`, to be compliant with alien "reading levels" (like WP Card levels), that start with `M`.
 */
export function convertReadingLevelToCardLevel(readingLevel) {
  return readingLevel;
}

/**
 * This is a placeholder method for converting our current card levels (from WordPress) that start with `M`, to be compliant with alien "card levels", that start with `M`.
 */
export function convertCardLevelToReadingLevel(cardLevel) {
  return cardLevel;
}

export const readingLevelMixin = {
  mixins: [nonReactiveMembersMixin(() => ({ ReadingLevel }))],
  methods: { convertReadingLevelToCardLevel, convertCardLevelToReadingLevel },
};
